<template>
    <div class="login-container bg-primary">
        <div class="login-page">
            <div class="form">
                <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on">
                    <img class="logo" src="@/assets/images/logo.png" alt="Logo de Wecake">
                    <el-divider></el-divider>
                    <img class="icon" src="@/assets/images/icon-admin.png" alt="Icono de administración">
                    <h4 class="hello mt-2 mb-4">Hola Admin!</h4>

                    <el-form-item prop="username" label="Usuario" >
                        <el-input
                            v-model="loginForm.username"
                            placeholder="Usuario"
                            type="text"
                            tabindex="1"
                            auto-complete="on"/>
                    </el-form-item>

                    <el-form-item class="mt-4" prop="password" label="Contraseña">
                        <el-input
                            show-password
                            suffix-icon=""
                            v-model="loginForm.password"
                            placeholder="Contraseña"
                            tabindex="2"
                            auto-complete="on"
                            @keyup.enter.native="handleLogin"/>
                    </el-form-item>

                    <el-button class="mt-4" :loading="loading" type="primary" @click.native.prevent="handleLogin">Entrar</el-button>

                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import {messages} from '../../constants/text-constants';

    export default {
        name: "Login",

        data() {
            const validateUsername = (rule, value, callback) => {
                if (value.length === 0) {
                    callback(new Error('Usuario incorrecto'))
                } else {
                    callback()
                }
            };
            const validatePassword = (rule, value, callback) => {
                if (value.length === 0) {
                    callback(new Error('Contraseña incorrecta'))
                } else {
                    callback()
                }
            };
            return {
                loginForm: {
                    username: '',
                    password: ''
                },
                loginRules: {
                    username: [{required: true, trigger: 'blur', validator: validateUsername}],
                    password: [{required: true, trigger: 'blur', validator: validatePassword}]
                },
                loading: false,
                redirect: undefined
            }
        },

        watch: {
            $route: {
                handler: function (route) {
                    this.redirect = route.query && route.query.redirect
                },
                immediate: true
            }
        },

        methods: {
            handleLogin() {
                this.$refs.loginForm.validate(valid => {
                    if (valid) {
                        this.loading = true;
                        this.$store.dispatch('user/login', this.loginForm).then(() => {
                            this.$router.push({path: this.redirect || '/'});
                            this.loading = false;
                        }).catch(error => {
                            this.loading = false;
                            if (error.response.status === 401) {
                                this.$message.error(messages.login.error);
                            }
                        })
                    } else {
                        return false;
                    }
                })
            }
        }
    }
</script>

<style scoped>

    .login-container {
        height: 100%;
    }

    .login-page {
        width: 360px;
        padding: 8% 0 0;
        margin: auto;
    }

    .form {
        position: relative;
        z-index: 1;
        background: #FFFFFF;
        max-width: 358px;
        margin: 0 auto 100px;
        padding: 45px;
        text-align: center;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
        border-radius: 4px;
    }

    .logo {
        width: 110px;
    }

    .icon {
        height: 35px;
    }

    .hello {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #5D5039;
    }

    >>> label {
        font-weight: normal;
        margin-bottom: 0;
    }

    .form button {
        width: 100%;
        margin-top: 20px;
    }

    .el-form-item {
        margin-bottom: 10px !important;
    }

</style>
